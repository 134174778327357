import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetProjectInfoByHostData,
  GetProjectInfoByHostVars,
  GetProjectInfoData,
  GetProjectInfoVars,
} from 'client/types/project';

const PROJECT_DATA_TO_RETRIEVE = `
  id
  name
  logo
  vendorLogo
  assetLogin
  hostKey
  assetKey
  defaultLang
  feVendorKey
  assetsUrl
  displayName
  manifestConfig
  locale
  languages
  policyFlags
  enabledTools
`;

export const GET_PROJECT_INFO: TypedDocumentNode<
  GetProjectInfoByHostData,
  GetProjectInfoByHostVars
> = gql`
  query getProjectInfoByHost ($host :String!) {
    getProjectInfoByHost (host :$host) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

export interface GetProjectInfoOptions {
  variables: {
    host: string;
  };
  notifyOnNetworkStatusChange: boolean;
}

export const GET_PROJECT_INFO_DEFAULT_OPTIONS = (
  hostname: string,
): GetProjectInfoOptions => ({
  variables: { host: hostname },
  notifyOnNetworkStatusChange: true,
});

export const GET_PROJECT_INFO_BY_ID: TypedDocumentNode<
  GetProjectInfoData,
  GetProjectInfoVars
> = gql`
  query getProjectInfo ($id :ID!) {
    getProjectInfo (id :$id) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

export interface GetProjectInfoIdOptions {
  variables: {
    id: string;
  };
  notifyOnNetworkStatusChange: boolean;
}

export const GET_PROJECT_INFO_BY_ID_DEFAULT_OPTIONS = (
  id: string,
): GetProjectInfoIdOptions => ({
  variables: { id },
  notifyOnNetworkStatusChange: true,
});
