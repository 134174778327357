import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import {
  confirmAccountLoader,
  authenticatedLoader,
  protectedLoader,
  changePasswordLoader,
} from 'router/loaders';

export const ROUTES_PATH = {
  home: '/',
  signIn: '/signIn',
  signUp: '/signUp',
  confirmAccount: '/confirm',
  forgotPassword: '/forgotPassword',
  changePassword: '/changePassword',
  requestLink: '/requestLink',
} as const;

const router = createBrowserRouter([
  {
    path: ROUTES_PATH.home,
    lazy: () => import('../pages/Home'),
    loader: protectedLoader,
  },
  {
    path: ROUTES_PATH.signIn,
    lazy: () => import('../pages/SignIn'),
    loader: authenticatedLoader,
  },
  {
    path: ROUTES_PATH.signUp,
    lazy: () => import('../pages/SignUp'),
    loader: authenticatedLoader,
  },
  {
    path: ROUTES_PATH.requestLink,
    lazy: () => import('../pages/RequestLink'),
    loader: authenticatedLoader,
  },
  {
    path: ROUTES_PATH.confirmAccount,
    lazy: () => import('../pages/ConfirmAccount'),
    loader: confirmAccountLoader,
  },
  {
    path: ROUTES_PATH.forgotPassword,
    lazy: () => import('../pages/ForgotPassword'),
    loader: authenticatedLoader,
  },
  {
    path: ROUTES_PATH.changePassword,
    lazy: () => import('../pages/ChangePassword'),
    loader: changePasswordLoader,
  },
  {
    path: '*',
    lazy: () => import('../pages/PageNotFound'),
  },
]);

const Router = () => {
  return <RouterProvider router={router} fallbackElement={<PageLoader />} />;
};

export default Router;
