import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectInfo } from 'types/project';

export interface ProjectState {
  data: ProjectInfo | null;
}

const initialState: ProjectState = {
  data: null,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectInfo: (state, action: PayloadAction<ProjectInfo>) => {
      return { ...state, data: action.payload };
    },
  },
});

export const { setProjectInfo } = projectSlice.actions;

export default projectSlice.reducer;
