import { TypedDocumentNode, gql } from '@apollo/client';
import {
  CreateClientAndUserV2Data,
  CreateClientAndUserV2Options,
  CreateClientAndUserV2Vars,
  GetUserByJWTData,
  GetUserByJWTVars,
  GetUserInfoOptions,
  LoginByProjectInput,
  LoginByProjectOptions,
  AskResetPasswordData,
  AskResetPasswordVars,
  AskResetPasswordOptions,
  ResetPasswordData,
  ResetPasswordVars,
  ResetPasswordOptions,
  CheckResetTokenVars,
  CheckResetTokenOptions,
  CheckResetTokenData,
  ConfirmUserAndResetPasswordData,
  ConfirmUserAndResetPasswordVars,
  ConfirmUserAndResetPasswordOptions,
  LoginClientByProjectVars,
  LoginClientByProjectData,
} from 'client/types/auth';

const LOGIN_CLIENT_BY_PROJECT_DATA = `
    token {
        accessToken
        refreshToken
        tokenType
        expiresIn
    }
    user{
        id
        firstName
        lastName
        email
        role
        client
        language
        locale
    }
`;

const USER_INFO_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      project_ids
      role
      language
      locale
`;

export const LOGIN_CLIENT_BY_PROJECT: TypedDocumentNode<
  LoginClientByProjectData,
  LoginClientByProjectVars
> = gql`
  mutation loginClientByProject($input: LoginByProjectInput!) {
    loginClientByProject(input: $input) {
        ${LOGIN_CLIENT_BY_PROJECT_DATA}
    }
  }
`;

export const GET_USER_INFO: TypedDocumentNode<
  GetUserByJWTData,
  GetUserByJWTVars
> = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_INFO_TO_RETRIEVE}
    }
  }
`;

export const CREATE_CLIENT_AND_USER_V2: TypedDocumentNode<
  CreateClientAndUserV2Data,
  CreateClientAndUserV2Vars
> = gql`
  mutation createClientAndUserV2(
    $project_id: ID!
    $input: ClientUserInputV2!
    $platform: Platforms!
    $isConfigurationComplete: Boolean
  ) {
    createClientAndUserV2(
      project_id: $project_id
      input: $input
      platform: $platform
      isConfigurationComplete: $isConfigurationComplete
    )
  }
`;

export const ASK_RESET_PASSWORD: TypedDocumentNode<
  AskResetPasswordData,
  AskResetPasswordVars
> = gql`
  mutation askResetPassword(
    $project_id: ID!
    $email: String!
    $platform: Platforms!
  ) {
    askResetPassword(
      project_id: $project_id
      email: $email
      platform: $platform
    )
  }
`;

export const CONFIRM_USER_AND_RESET_PASSWORD: TypedDocumentNode<
  ConfirmUserAndResetPasswordData,
  ConfirmUserAndResetPasswordVars
> = gql`
  mutation confirmUserAndResetPassword(
    $project_id: ID!
    $token: String!
    $password: String!
    $newPassword: String!
  ) {
    confirmUserAndResetPassword(
      project_id: $project_id
      token: $token
      password: $password
      newPassword: $newPassword
    )
  }
`;

export const RESET_PASSWORD: TypedDocumentNode<
  ResetPasswordData,
  ResetPasswordVars
> = gql`
  mutation resetPassword(
    $project_id: ID!
    $token: String!
    $password: String!
  ) {
    resetPassword(project_id: $project_id, token: $token, password: $password)
  }
`;

export const CHECK_RESET_TOKEN: TypedDocumentNode<
  CheckResetTokenData,
  CheckResetTokenVars
> = gql`
  mutation checkResetToken($project_id: ID!, $token: String!) {
    checkResetToken(project_id: $project_id, token: $token)
  }
`;

export const GET_USER_INFO_DEFAULT_OPTIONS = (
  projectId: string,
): GetUserInfoOptions => ({
  variables: {
    project_id: projectId,
  },
  fetchPolicy: 'network-only',
});

export const LOGIN_BY_PROJECT_DEFAULT_OPTIONS = (
  loginByProjectInput: LoginByProjectInput,
): LoginByProjectOptions => ({
  variables: {
    input: loginByProjectInput,
  },
  fetchPolicy: 'no-cache',
});

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const ASK_RESET_PASSWORD_DEFAULT_OPTIONS = (
  askResetPasswordInput: AskResetPasswordVars,
): AskResetPasswordOptions => ({
  variables: askResetPasswordInput,
  fetchPolicy: 'no-cache',
});

export const CREATE_CLIENT_AND_USER_V2_DEFAULT_OPTIONS = (
  createClientsInput: CreateClientAndUserV2Vars,
): CreateClientAndUserV2Options => ({
  variables: createClientsInput,
  fetchPolicy: 'no-cache',
});

export const CONFIRM_USER_AND_RESET_PASSWORD_DEFAULT_OPTIONS = (
  confirmUserAndResetPasswordInput: ConfirmUserAndResetPasswordVars,
): ConfirmUserAndResetPasswordOptions => ({
  variables: confirmUserAndResetPasswordInput,
  fetchPolicy: 'no-cache',
});

export const RESET_PASSWORD_DEFAULT_OPTIONS = (
  ResetPasswordInput: ResetPasswordVars,
): ResetPasswordOptions => ({
  variables: ResetPasswordInput,
  fetchPolicy: 'no-cache',
});

export const CHECK_RESET_TOKEN_DEFAULT_OPTIONS = (
  CheckResetTokenInput: CheckResetTokenVars,
): CheckResetTokenOptions => ({
  variables: CheckResetTokenInput,
  fetchPolicy: 'no-cache',
});
