import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import projectReducer from './slices/project/reducer';
import authReducer from './slices/auth/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
});

const storeOptions = {
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
};

const store = configureStore({
  ...storeOptions,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
