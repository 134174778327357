import React from 'react';
import { ErrorPage, LanguageSelector, LazyImage, Spinner } from '@tecma/ds';
import { AvailableLanguage } from '@tecma/ds/lib/components/LanguageSelector/constants';
import { useTranslation } from '@tecma/i18n';

import PageLoader from 'components/PageLoader';
import useDeviceDimension from 'hooks/useDeviceDimension';
import Router from 'router';
import useProjectInfo from 'hooks/useProjectInfo';

const App: React.FC = () => {
  const { data, loading, error } = useProjectInfo();
  const { i18n } = useTranslation();
  useDeviceDimension();

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  if (loading) return <PageLoader />;

  if (error) return <ErrorPage />;

  if (data) {
    return (
      <LazyImage.Background
        className="app-background"
        loadingElement={<Spinner />}
        errorElement={<span style={{ display: 'none' }} />}
        src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${data.displayName}/global/img/login.jpg`}
      >
        <Router />
        <div className="app-footer">
          <LanguageSelector
            transparent
            languages={data.languages as AvailableLanguage[]}
            currentLanguage={i18n.language}
            onChangeLanguage={onLanguageChange}
            position={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
        </div>
      </LazyImage.Background>
    );
  }
  return null;
};

export default App;
