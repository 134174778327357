import Cookies from 'js-cookie';
import {
  HOSTNAME_QUERY_PARAM_KEY,
  LOCALE_QUERY_PARAM_KEY,
  PROJECT_ID_PARAM_KEY,
} from '../constants';

export const detectUrl = () => {
  let hostname =
    window.location.hostname.indexOf('www.') >= 0
      ? window.location.hostname
      : `www.${window.location.hostname}`;
  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');
  if (
    hostname.indexOf('localhost') >= 0 ||
    hostname.indexOf('.ddns.net') >= 0 ||
    hostname.indexOf('biz-tecma') >= 0 ||
    hostname.indexOf('-demo.tecmasolutions.com') >= 0
  ) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      localStorage.clear();
      localStorage.removeItem(process.env.REACT_APP_NAME!);
      localStorage.setItem(process.env.REACT_APP_NAME!, hostnameParam);
    }

    const myProjectDevHostname = localStorage.getItem(
      process.env.REACT_APP_NAME!,
    );

    if (
      myProjectDevHostname &&
      myProjectDevHostname.length > 0 &&
      myProjectDevHostname !== ''
    ) {
      return myProjectDevHostname;
    }
    hostname =
      Cookies.get(HOSTNAME_QUERY_PARAM_KEY) ?? process.env.REACT_APP_HOST!;
  }
  return hostname;
};

export const getLanguageFromQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const languageParam = urlParams.get(LOCALE_QUERY_PARAM_KEY);
  return languageParam ?? '';
};

export const getProjectIDFromQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const projectIDParam = urlParams.get(PROJECT_ID_PARAM_KEY);
  return projectIDParam ?? '';
};
