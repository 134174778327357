import React from 'react';
import { createRoot } from 'react-dom/client';
import { initI18n } from '@tecma/i18n';
import { WinstonProvider } from 'winston-react';
import { ApolloProvider } from '@apollo/client';
import { ErrorPage } from '@tecma/ds';
import { logger } from '@tecma/logs-fe';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import { getProjectLogoUrl } from 'utils/project';
import { getLanguageFromQueryParams } from 'utils';
import App from './App';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { graphqlClient } from './client/GraphqlClient';
import defaultTranslation from './i18n/en/translation.json';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container!);
const currentLanguage =
  getLanguageFromQueryParams() ??
  localStorage.getItem('i18nextLng') ??
  undefined;

export const i18n = initI18n(defaultTranslation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: process.env.REACT_APP_NAME!,
  apiBase: process.env.REACT_APP_API_URI ?? '',
  expirationTime: 1000 * 60,
  cache: 'no-cache',
});

root.render(
  <React.StrictMode>
    <WinstonProvider logger={logger}>
      <ErrorBoundary
        FallbackComponent={() => (
          <ErrorPage logo={getProjectLogoUrl()} language={currentLanguage} />
        )}
        onError={(error) => {
          logger.error('APPLICATION_ERROR', { error });
        }}
      >
        <ApolloProvider client={graphqlClient}>
          <Provider store={store}>
            <App />
          </Provider>
        </ApolloProvider>
      </ErrorBoundary>
    </WinstonProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
