import Cookies from 'js-cookie';
import psl, { ParsedDomain, ParseError } from 'psl';
import { Token, User } from 'types/auth';
import {
  CLIENT_ID_RENT_COOKIE_KEY,
  EXPIRES_IN_COOKIE_KEY,
  JWT_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
  USER_LANGUAGE_COOKIE_KEY,
} from '../constants';

export const isParsedDomain = (
  arg: psl.ParsedDomain | psl.ParseError,
): arg is ParsedDomain => {
  return Object.hasOwn(arg, 'domain');
};

const getPslDomain = (hostname: string) => {
  const pslUrl: ParsedDomain | ParseError = psl.parse(hostname);
  if (isParsedDomain(pslUrl)) {
    return pslUrl.domain;
  }
  return null;
};

export const getDomain = (toolHostname?: string) => {
  let myAccountDomain = 'localhost';
  let toolDomain = 'localhost';
  if (window.location.hostname !== 'localhost') {
    myAccountDomain = getPslDomain(window.location.hostname) ?? myAccountDomain;
  }
  if (!toolHostname) return myAccountDomain;

  if (toolHostname !== 'localhost') {
    toolDomain = getPslDomain(toolHostname) ?? toolDomain;
  }
  return { myAccountDomain, toolDomain };
};

export const getCookieOptions = (toolHostname?: string) => {
  const domain = getDomain(toolHostname);
  return {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: typeof domain === 'string' ? domain : domain.myAccountDomain,
    toolDomain:
      typeof domain === 'object' && domain.toolDomain !== domain.myAccountDomain
        ? domain.toolDomain
        : null,
  };
};

export const setCookies = ({
  user,
  token,
  toolHostname,
}: {
  user: User;
  token: Token;
  toolHostname?: string;
}) => {
  const { toolDomain, ...cookieOptions } = getCookieOptions(toolHostname);
  Cookies.set(JWT_TOKEN_COOKIE_KEY, token.accessToken, cookieOptions);
  Cookies.set(REFRESH_TOKEN_COOKIE_KEY, token.refreshToken, cookieOptions);
  Cookies.set(EXPIRES_IN_COOKIE_KEY, token.expiresIn, cookieOptions);
  Cookies.set(CLIENT_ID_RENT_COOKIE_KEY, user.id, cookieOptions);
  Cookies.set(USER_LANGUAGE_COOKIE_KEY, user.language, cookieOptions);

  if (toolDomain) {
    const toolCookieOptions = {
      ...cookieOptions,
      domain: toolDomain,
    };
    Cookies.set(JWT_TOKEN_COOKIE_KEY, token.accessToken, toolCookieOptions);
    Cookies.set(
      REFRESH_TOKEN_COOKIE_KEY,
      token.refreshToken,
      toolCookieOptions,
    );
    Cookies.set(EXPIRES_IN_COOKIE_KEY, token.expiresIn, toolCookieOptions);
    Cookies.set(CLIENT_ID_RENT_COOKIE_KEY, user.id, toolCookieOptions);
    Cookies.set(USER_LANGUAGE_COOKIE_KEY, user.language, toolCookieOptions);
  }
};
