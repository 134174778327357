// COOKIES KEYS
export const PROJECT_NAME_COOKIE_KEY = 'projectName';
export const PROJECT_ID_COOKIE_KEY = 'project_id';
export const JWT_TOKEN_COOKIE_KEY = 'jwtRent';
export const REFRESH_TOKEN_COOKIE_KEY = 'refreshTokenRent';
export const EXPIRES_IN_COOKIE_KEY = 'expiresInRent';
export const CLIENT_ID_RENT_COOKIE_KEY = 'clientIdRent';
export const USER_LANGUAGE_COOKIE_KEY = 'userLanguage';

// QUERY PARAMS KEYS
export const TOKEN_QUERY_PARAM_KEY = 'token';
export const PROJECT_ID_PARAM_KEY = 'projectId';
export const PASSWORD_QUERY_PARAM_KEY = 'password';
export const PLATFORM_QUERY_PARAM_KEY = 'platform';
export const HOSTNAME_QUERY_PARAM_KEY = 'hostname';
export const LOCALE_QUERY_PARAM_KEY = 'locale';
export const DATA_BY_CLIENT_PARAM_KEY = 'dataByClient';
export const REDIRECT_URL_QUERY_PARAM_KEY = 'redirectUrl';
export const IS_CONFIGURATION_COMPLETE_QUERY_PARAM_KEY =
  'isConfigurationComplete';

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

export const POLICY_FLAG_LANGUAGES = [
  'ita',
  'eng',
  'deu',
  'fra',
  'esp',
] as const;

export enum PLATFORM {
  myhome = 'MYHOME',
  spaceConfigurator = 'SPACECONFIGURATOR',
  coliving = 'COLIVING',
  businessPlatform = 'BUSINESSPLATFORM',
}
