import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginClientByProject } from 'client/types/auth';
import { Token, User } from 'types/auth';

export interface AuthState {
  token: Token;
  user: User;
}

const initialState: AuthState = {
  token: {
    accessToken: '',
    expiresIn: '',
    refreshToken: '',
    tokenType: '',
    hostKey: '',
    expiredToken: '',
  },
  user: {
    client: '',
    createdOn: '',
    email: '',
    firstName: '',
    hasGoogleToken: false,
    myhome_version: '',
    id: '',
    language: '',
    lastName: '',
    project_ids: [],
    role: '',
    tel: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state, action: PayloadAction<LoginClientByProject>) => {
      return { ...state, ...action.payload };
    },
    logoutAction: () => {
      return initialState;
    },
    setUserAction: (state, action: PayloadAction<User>) => {
      return { ...state, user: action.payload };
    },
  },
});

export const { loginAction, logoutAction, setUserAction } = authSlice.actions;

export default authSlice.reducer;
