import Cookies from 'js-cookie';
import { PolicyFlagLanguage } from 'types/project';
import { PROJECT_NAME_COOKIE_KEY } from '../constants';

export const getPrivacyPolicyLangByCurrentLanguage = (
  language: string,
): PolicyFlagLanguage => {
  const lang = language.split('-')[0];
  if (lang === 'it') return 'ita';
  if (lang === 'es') return 'esp';
  if (lang === 'en') return 'eng';
  if (lang === 'fr') return 'fra';
  if (lang === 'de') return 'deu';
  return 'ita';
};

export const getProjectLogoUrl = () => {
  const projectName = Cookies.get(PROJECT_NAME_COOKIE_KEY) ?? '';
  return `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(
    projectName,
  )}/global/img/logo192.png`;
};
