import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation, loadResources } from '@tecma/i18n';
import { logger } from '@tecma/logs-fe';
import Cookies from 'js-cookie';

import { setProjectInfo } from 'store/slices/project/reducer';
import { useAppDispatch } from 'store/hooks';
import type { ProjectInfo } from 'types/project';
import {
  detectUrl,
  getLanguageFromQueryParams,
  getProjectIDFromQueryParams,
} from '../utils';
import { getCookieOptions } from '../utils/auth';
import { loadScssFromDB } from '../theme/utils';
import { PROJECT_ID_COOKIE_KEY, PROJECT_NAME_COOKIE_KEY } from '../constants';
import Client from '../client/Client';

const useProjectInfo = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const projectIdQueryParam = getProjectIDFromQueryParams();

  const onProjectInfoData = async (project: ProjectInfo) => {
    if (project) {
      const projectClone = project;
      const cookieOptions = getCookieOptions();
      i18n.changeLanguage(
        getLanguageFromQueryParams() ?? projectClone?.defaultLang,
      );
      dispatch(setProjectInfo(projectClone));
      Cookies.set(PROJECT_ID_COOKIE_KEY, projectClone.id, cookieOptions);
      Cookies.set(
        PROJECT_NAME_COOKIE_KEY,
        projectClone.displayName,
        cookieOptions,
      );
      document.title = `${projectClone.displayName} | My Account`;
      loadScssFromDB(projectClone.style);
      await loadResources({
        id: projectClone.id,
        displayName: projectClone.displayName,
        languages: projectClone.languages,
      });
      setLoading(false);
      logger.info('GET_PROJECT_INFO_SUCCESS', {
        getProjectInfoData: projectClone,
      });
    } else {
      setError(true);
      logger.error(
        `NO PROJECT FOUND WITH HOSTNAME: ${detectUrl()}. CHECK IF NAME IS CORRECT`,
      );
      setLoading(false);
    }
  };

  const [
    getProjectInfoByIdQuery,
    { data: getProjectInfoData, refetch: refetchProjectInfo },
  ] = useLazyQuery(Client.GET_PROJECT_INFO_BY_ID, {
    onCompleted: (data) => onProjectInfoData(data.getProjectInfo),
    onError: (errorGraphQL) => {
      setError(true);
      logger.error('GET_PROJECT_INFO_ERROR', { errorGraphQL });
      setLoading(false);
    },
  });
  const [
    getProjectInfoByHostQuery,
    { data: getProjectInfoByHostData, refetch: refetchProjectInfoByHost },
  ] = useLazyQuery(Client.GET_PROJECT_INFO, {
    ...Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(detectUrl()),
    onCompleted: (data) => onProjectInfoData(data.getProjectInfoByHost),
    onError: (errorGraphQL) => {
      setError(true);
      logger.error('GET_PROJECT_INFO_BY_HOST_ERROR', { errorGraphQL });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (projectIdQueryParam) {
      getProjectInfoByIdQuery(
        Client.GET_PROJECT_INFO_BY_ID_DEFAULT_OPTIONS(projectIdQueryParam),
      );
    } else {
      getProjectInfoByHostQuery();
    }
  }, [projectIdQueryParam]);

  return {
    data:
      getProjectInfoData?.getProjectInfo ??
      getProjectInfoByHostData?.getProjectInfoByHost,
    loading,
    error,
    refetch: refetchProjectInfo ?? refetchProjectInfoByHost,
  };
};

export default useProjectInfo;
