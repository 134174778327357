import { ProjectStyle } from 'types/project';

export const loadScssFromDB = (initiativesStyles?: ProjectStyle): void => {
  const loadColorsStyle = (colorsStyle: { [key: string]: string }) => {
    if (colorsStyle) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(colorsStyle)) {
        if (colorsStyle[key] !== '') {
          if (
            [
              'border-radius-element',
              'border-radius-external',
              'border-radius-internal',
              'border-radius-standard',
              'border-width-element',
              'border-width-external',
              'border-width-internal',
              'border-width-standard',
            ].includes(key)
          ) {
            document.documentElement.style.setProperty(
              `--${key}`,
              `${colorsStyle[key]}px`,
            );
          } else {
            document.documentElement.style.setProperty(
              `--${key}`,
              colorsStyle[key],
            );
          }
        }
      }
    }
  };
  loadColorsStyle(initiativesStyles ?? {});
};
