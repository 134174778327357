import { useEffect } from 'react';

const useDeviceDimension = () => {
  useEffect(() => {
    const setRealDeviceHeight = () => {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      document.documentElement.style.setProperty(
        '--device-view-height',
        `${viewportHeight}px`,
      );
      document.documentElement.style.setProperty(
        '--device-view-width',
        `${viewportWidth}px`,
      );
    };
    setRealDeviceHeight();
    window.addEventListener('resize', setRealDeviceHeight);
    return () => {
      window.removeEventListener('resize', setRealDeviceHeight);
    };
  }, []);
};

export default useDeviceDimension;
