import { LoaderFunction, redirect } from 'react-router-dom';
import { AuthUtils } from '@tecma/auth-utils';
import Client from 'client/Client';
import Cookies from 'js-cookie';
import { logger } from '@tecma/logs-fe';
import { ROUTES_PATH } from 'router';
import { CheckTokenParams, User } from 'types/auth';
import { AUTH_ERRORS_TRANSLATION_MAP } from 'client/errors/auth';
import i18n from '@tecma/i18n';
import { Platform } from 'client/types/auth';
import { detectUrl } from 'utils';
import { graphqlClient } from '../client/GraphqlClient';
import {
  JWT_TOKEN_COOKIE_KEY,
  PASSWORD_QUERY_PARAM_KEY,
  PLATFORM_QUERY_PARAM_KEY,
  PROJECT_ID_COOKIE_KEY,
  PROJECT_ID_PARAM_KEY,
  REDIRECT_URL_QUERY_PARAM_KEY,
  TOKEN_QUERY_PARAM_KEY,
} from '../constants';

const isUserLogged = async (): Promise<User | null> => {
  const jwtCookie = Cookies.get(JWT_TOKEN_COOKIE_KEY);
  const projectId = Cookies.get(PROJECT_ID_COOKIE_KEY);
  if (jwtCookie && projectId) {
    try {
      const response = await graphqlClient.query({
        query: Client.GET_USER_INFO,
        variables: { project_id: projectId },
      });
      if (response.data.getUserByJWT) {
        return response.data.getUserByJWT;
      }
      await graphqlClient.query({ query: Client.LOGOUT });
      AuthUtils.logout();
      return null;
    } catch (e) {
      logger.error(e);
      return null;
    }
  }
  AuthUtils.logout();
  return null;
};

const getMyHomeDefaultURL = async (): Promise<string | null> => {
  try {
    const response = await graphqlClient.query({
      query: Client.GET_PROJECT_INFO,
      variables: { host: detectUrl() },
    });
    if (response.data.getProjectInfoByHost) {
      const MyHomeConfig =
        response.data.getProjectInfoByHost?.enabledTools?.find(
          (tools) => tools.name === 'MyHome',
        );
      if (MyHomeConfig) {
        return MyHomeConfig.url;
      }
      return null;
    }
    return null;
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export const protectedLoader: LoaderFunction = async () => {
  const userLogged = await isUserLogged();
  if (userLogged) {
    const redirectToMyHomeUrl = await getMyHomeDefaultURL();
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrlQueryParam = urlParams.get(REDIRECT_URL_QUERY_PARAM_KEY);
    const projectIdParam = urlParams.get(PROJECT_ID_PARAM_KEY);
    const projectId =
      projectIdParam ?? Cookies.get(PROJECT_ID_COOKIE_KEY) ?? '';
    if (redirectUrlQueryParam || redirectToMyHomeUrl) {
      AuthUtils.redirectToTool(
        redirectUrlQueryParam ?? redirectToMyHomeUrl ?? '',
        projectId,
        userLogged.notificationLanguage ?? i18n.language,
      );
    } else {
      return redirect(ROUTES_PATH.home);
    }
  }
  return redirect(ROUTES_PATH.signIn);
};

export const authenticatedLoader: LoaderFunction = async () => {
  const userLogged = await isUserLogged();
  if (userLogged) {
    const redirectToMyHomeUrl = await getMyHomeDefaultURL();
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrlQueryParam = urlParams.get(REDIRECT_URL_QUERY_PARAM_KEY);
    const projectIdParam = urlParams.get(PROJECT_ID_PARAM_KEY);
    const projectId =
      projectIdParam ?? Cookies.get(PROJECT_ID_COOKIE_KEY) ?? '';
    if (redirectToMyHomeUrl || redirectUrlQueryParam) {
      AuthUtils.redirectToTool(
        redirectUrlQueryParam ?? redirectToMyHomeUrl ?? '',
        projectId,
        userLogged.notificationLanguage ?? i18n.language,
      );
    } else {
      return redirect(ROUTES_PATH.home);
    }
  }
  return null;
};

export const checkTokenLoader = async (params: CheckTokenParams) => {
  const userLogged = await isUserLogged();
  if (userLogged) {
    return redirect(ROUTES_PATH.home);
  }
  try {
    const response = await graphqlClient.query({
      query: Client.CHECK_RESET_TOKEN,
      variables: { project_id: params.projectID, token: params.token },
    });
    if (
      [
        AUTH_ERRORS_TRANSLATION_MAP.EXPIRED_TOKEN,
        AUTH_ERRORS_TRANSLATION_MAP.INVALID_TOKEN,
      ].includes(response.data?.checkResetToken)
    ) {
      const requestLinkUrl = params.platform
        ? `${ROUTES_PATH.requestLink}?platform=${params.platform}`
        : ROUTES_PATH.requestLink;
      return redirect(requestLinkUrl);
    }
    return null;
  } catch {
    return null;
  }
};

export const confirmAccountLoader: LoaderFunction = async (args) => {
  const url = new URL(args.request.url);
  const token = url.searchParams.get(TOKEN_QUERY_PARAM_KEY) ?? undefined;
  const password = url.searchParams.get(PASSWORD_QUERY_PARAM_KEY) ?? undefined;
  const projectID = url.searchParams.get(PROJECT_ID_PARAM_KEY) ?? undefined;
  const platform =
    (url.searchParams.get(PLATFORM_QUERY_PARAM_KEY) as Platform) ?? undefined;
  if (token && password && projectID) {
    const checkTokenParams = {
      token,
      projectID,
      platform,
    };
    return checkTokenLoader(checkTokenParams);
  }
  return redirect(ROUTES_PATH.signIn);
};

export const changePasswordLoader: LoaderFunction = async (args) => {
  const url = new URL(args.request.url);
  const token = url.searchParams.get(TOKEN_QUERY_PARAM_KEY) ?? undefined;
  const projectID = url.searchParams.get(PROJECT_ID_PARAM_KEY) ?? undefined;
  const platform =
    (url.searchParams.get(PLATFORM_QUERY_PARAM_KEY) as Platform) ?? undefined;
  if (token && projectID) {
    const checkTokenParams = {
      token,
      projectID,
      platform,
    };
    return checkTokenLoader(checkTokenParams);
  }
  return redirect(ROUTES_PATH.signIn);
};
