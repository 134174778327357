import { AuthErrorTranslationMap } from 'client/types/auth';

export const InvalidCredentialsLoginErrorMessages = [
  'Incorrect email or refreshToken',
  'User not enabled for this project',
  'Incorrect email or password',
];

export const ExpiredLoginErrorMessage = 'Expired';
export const UserAlreadyExistErrorMessage = 'User already exists';

export const CHANGE_PASSWORD_ERRORS = [
  'passwordEqualsEmail',
  'passwordEqualsName',
  'notSecurePassword',
  'passwordAlreadyUsed',
  'wrongPassword',
];

export const AUTH_ERRORS = [
  'INVALID_TOKEN',
  'EXPIRED_TOKEN',
  'WRONG_PASSWORD',
  'PASSWORD_EQUALS_USER_DETAILS',
  'NOT_SECURE_PASSWORD',
  'PASSWORD_ALREADY_USED',
  'USER_DISABLED',
  'PASSWORD_EXPIRED',
  'WRONG_ROLE',
  'AUTHENTICATION_ERROR',
] as const;

export const AUTH_ERRORS_TRANSLATION_MAP: AuthErrorTranslationMap = {
  INVALID_TOKEN: 'invalidToken',
  EXPIRED_TOKEN: 'expiredToken',
  WRONG_PASSWORD: 'wrong_password',
  PASSWORD_EQUALS_USER_DETAILS: 'password_equals_user_details',
  NOT_SECURE_PASSWORD: 'not_secure_password',
  PASSWORD_ALREADY_USED: 'password_already_used',
  USER_DISABLED: 'user_disabled',
  PASSWORD_EXPIRED: 'password_expired',
  WRONG_ROLE: 'wrong_role',
  AUTHENTICATION_ERROR: 'authentication_error',
};
