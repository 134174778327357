import React from 'react';
import { Spinner } from '@tecma/ds';

import './page-loader.scss';

const PageLoader: React.FC = () => {
  return (
    <div data-testid="pageLoader" className="page-loader">
      <Spinner className="page-loader__spinner" />
    </div>
  );
};

export default React.memo(PageLoader);
